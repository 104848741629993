<template>
  <div class="error-box-403">
    <div>
      <h1>500</h1>
      <h3>
        {{ $t('errorLog.errorTypes.500') }}
        <a href="javascript:history.back()">{{ $t('back') }}</a>
      </h3>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
body .error-box-403 {
  background-color: white;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100000;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  h1 {
    font-size: 100pt;
  }

  h3 {
    display: inline;
  }
}
</style>
